<template>
    <div>
        <div class="header">
            <van-nav-bar :title="$t('identity.title')" left-arrow @click-left="$router.go(-1)" />
        </div>
        <div class="maincontent">
            <van-row class="spacing">
                <van-form ref="form" size="large">
                    <van-cell-group inset>
                        <van-field v-model="identity.realname" :label="$t('identity.realname')"
                            :placeholder="$t('identity.notice1')" size="large" />
                        <van-field v-model="identity.country" :label="$t('identity.regions')"
                            :placeholder="$t('identity.notice2')" size="large" />
                        <van-radio-group v-model="value1" size="large">
                            <van-cell-group>
                                <van-cell :title="$t('护照')" clickable @click="value1 = '1'" size="large">
                                    <template #right-icon>
                                        <van-radio name="1" />
                                    </template>
                                </van-cell>
                                <van-cell :title="$t('身份证')" clickable @click="value1 = '2'" size="large">
                                    <template #right-icon>
                                        <van-radio name="2" />
                                    </template>
                                </van-cell>
                                <van-cell :title="$t('驾驶证')" clickable @click="value1 = '3'" size="large">
                                    <template #right-icon>
                                        <van-radio name="3" />
                                    </template>
                                </van-cell>
                                <van-cell :title="$t('社会保障卡')" clickable @click="value1 = '4'" size="large">
                                    <template #right-icon>
                                        <van-radio name="4" />
                                    </template>
                                </van-cell>
                            </van-cell-group>
                        </van-radio-group>
                        <!-- <van-row class="noDate" style="margin-top: 10px;">
                            <van-tag type="primary">{{ $t('护照') }}</van-tag> /
                            <van-tag type="success">{{ $t('身份证') }}</van-tag> /
                            <van-tag type="danger">{{ $t('驾驶证') }}</van-tag> /
                            <van-tag type="warning">{{ $t('社会保障卡') }}</van-tag>
                        </van-row> -->
                        <van-field name="uploader" size="large">
                            <template #input>
                                <div style="margin: 0 auto;">
                                    <van-uploader v-model="uploader" :after-read="afterRead" />
                                </div>
                            </template>
                        </van-field>

                    </van-cell-group>
                </van-form>
                <van-button round type="info" v-if="identity.oauth === '0'"
                    style="color: white; background: rgb(4, 172, 146); border-color: rgb(4, 172, 146);margin-top: 20px;height: 35px;width: 100px;"
                    @click.prevent="onSubmit">{{ $t('common.submit') }}</van-button>
                <van-button round type="info" v-if="identity.oauth === '1'"
                    style="color: white; background: rgb(4, 172, 146); border-color: rgb(4, 172, 146);margin-top: 20px;height: 35px;width: 100px;">{{
                        $t('identity.auth1') }}</van-button>
                <van-button round type="info" v-if="identity.oauth === '2'"
                    style="color: white; background: rgb(4, 172, 146); border-color: rgb(4, 172, 146);margin-top: 20px;height: 35px;width: 100px;">{{
                        $t('identity.auth2') }}</van-button>

                <!-- <van-button v-if="identity.oauth === '0'" class="submitBtn" >{{
                    $t('common.submit') }}</van-button>
                <van-button v-if="identity.oauth === '1'" class="submitBtn">{{
                    $t('identity.auth1')
                }}</van-button>
                <van-button v-if="identity.oauth === '2'" class="submitBtn">{{
                    $t('identity.auth2')
                }}</van-button> -->
            </van-row>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant';

export default {
    data() {
        return {
            identity: {
                realname: "", // 真实姓名
                country: "",  // 地区
                address: "No address",  // 详细地址
                image: "", // 图片证明：护照、驾驶证、身份证、社会安全卡
                image2: "", // 图片证明：护照、驾驶证、身份证、社会安全卡
                identity_card_front_image: "", // 测试
            },
            rules: [],
            uploader: [],
            value1: '1',
        };
    },
    created() {
        this.getinfo();
    },
    methods: {
        async getinfo() {
            const { data } = await this.$http.get('/home/home/getindentity');
            // console.log("data", data);
            if (data) {
                if (data.code === 200) {
                    this.identity = data.data;
                }
            }
        },
        // 上传图片 得到图片file
        async afterRead(file) {
            file.status = 'uploading';
            file.message = 'Wait...';

            const param = new FormData();
            param.append('file', file.file);
            const { data } = await this.$http.post('/home/user/uploadimg', param);
            if (data) {
                if (this.identity.image) {
                    this.identity.image2 = data.data.url;
                }
                else {
                    this.identity.image = data.data.url;
                    this.identity.identity_card_front_image = data.data.url;
                }
                file.status = 'success';
            }
            else {
                file.status = 'failed';
                file.message = 'Fail'; // 上传失败
            }
        },
        async onSubmit() {
            this.identity.address = "No address";
            if (!this.identity.realname || !this.identity.image || !this.identity.country) {
                return Toast("No");
            }

            // 除了护照,需要上传2张图片
            if (this.value1 !== '1') {
                if (!this.identity.image2 || !this.identity.image) {
                    return Toast("Please upload 2 images");
                }
            }

            const { data } = await this.$http.post('/home/home/identity', this.identity);
            if (data) {
                if (data.code === 200) {
                    this.$toast.success(this.$t('common.success'));
                    setTimeout(() => {
                        this.$router.push('/index');
                    }, 300);
                }
                else {
                    Toast("Data Error");
                }
            }
        }
    }
};
</script>

<style lang="less" scoped>
.maincontent {
    padding-top: 50px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    font-size: 0.93333rem;
    background: #f7f7f7;
    height: 100vh;

    .spacing {
        margin-top: 1rem;



        .submitBtn {
            margin-top: 20%;
            width: 80%;
            letter-spacing: 0.53333rem;
            height: 4rem;
            font-size: 0.93333rem;
            color: #fff;
            background-color: #004ea3;
            border-radius: 0.53333rem;
            border: none;
            box-shadow: 0 0 1.33333rem #004ea3;
        }
    }
}

.van-cell__title {
    text-align: left;
}
</style>
